export const state = () => ({
  isBurgerMenuOpen: false,
  navItems: [
    {
      hash: 'about',
      to: '/about',
    },
    {
      hash: 'projects',
      to: '/projects',
    },
    {
      hash: 'lab',
      to: '/lab',
    },
    // {
    //   hash: 'journal',
    //   to: '/journal',
    // },
    {
      hash: 'contact',
      to: '/contact',
    },
  ],
});

export const mutations = {
  setIsBurgerMenuOpen(state, payload) {
    state.isBurgerMenuOpen = payload;
  },
};

export const getters = {

};

export const actions = {

};
