export const Newsletter = () => import('../../components/Newsletter.vue' /* webpackChunkName: "components/newsletter" */).then(c => wrapFunctional(c.default || c))
export const AboutHero = () => import('../../components/about/AboutHero.vue' /* webpackChunkName: "components/about-hero" */).then(c => wrapFunctional(c.default || c))
export const AboutHeroGradient = () => import('../../components/about/AboutHeroGradient.vue' /* webpackChunkName: "components/about-hero-gradient" */).then(c => wrapFunctional(c.default || c))
export const AboutTelemedicine = () => import('../../components/about/AboutTelemedicine.vue' /* webpackChunkName: "components/about-telemedicine" */).then(c => wrapFunctional(c.default || c))
export const ContactHero = () => import('../../components/contact/ContactHero.vue' /* webpackChunkName: "components/contact-hero" */).then(c => wrapFunctional(c.default || c))
export const HomeFeatures = () => import('../../components/home/HomeFeatures.vue' /* webpackChunkName: "components/home-features" */).then(c => wrapFunctional(c.default || c))
export const HomeHero = () => import('../../components/home/HomeHero.vue' /* webpackChunkName: "components/home-hero" */).then(c => wrapFunctional(c.default || c))
export const HomeHeroGradient = () => import('../../components/home/HomeHeroGradient.vue' /* webpackChunkName: "components/home-hero-gradient" */).then(c => wrapFunctional(c.default || c))
export const HomePartners = () => import('../../components/home/HomePartners.vue' /* webpackChunkName: "components/home-partners" */).then(c => wrapFunctional(c.default || c))
export const JournalArticle = () => import('../../components/journal/JournalArticle.vue' /* webpackChunkName: "components/journal-article" */).then(c => wrapFunctional(c.default || c))
export const LabCta = () => import('../../components/lab/LabCta.vue' /* webpackChunkName: "components/lab-cta" */).then(c => wrapFunctional(c.default || c))
export const LabHero = () => import('../../components/lab/LabHero.vue' /* webpackChunkName: "components/lab-hero" */).then(c => wrapFunctional(c.default || c))
export const LabTeam = () => import('../../components/lab/LabTeam.vue' /* webpackChunkName: "components/lab-team" */).then(c => wrapFunctional(c.default || c))
export const ProjectsCta = () => import('../../components/projects/ProjectsCta.vue' /* webpackChunkName: "components/projects-cta" */).then(c => wrapFunctional(c.default || c))
export const ProjectsHero = () => import('../../components/projects/ProjectsHero.vue' /* webpackChunkName: "components/projects-hero" */).then(c => wrapFunctional(c.default || c))
export const ProjectsProject = () => import('../../components/projects/ProjectsProject.vue' /* webpackChunkName: "components/projects-project" */).then(c => wrapFunctional(c.default || c))
export const BurgerDialog = () => import('../../components/ui/BurgerDialog.vue' /* webpackChunkName: "components/burger-dialog" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/ui/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const CookieBar = () => import('../../components/ui/CookieBar.vue' /* webpackChunkName: "components/cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/ui/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/ui/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const LangSwitch = () => import('../../components/ui/LangSwitch.vue' /* webpackChunkName: "components/lang-switch" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
