
import Logo from '~/assets/img/logo.svg?inline';

export default {
  components: {
    Logo,
  },

  data() {
    return {
      isScrolled: false,
    };
  },

  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll() {
      const scrollTop = window.scrollY;
      this.isScrolled = scrollTop > 120;
    },
  },
};
