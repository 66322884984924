import Vue from 'vue';

Vue.directive('wrap-words', {
  inserted: (el, options) => {
    const delay = options.value && options.value.delay ? options.value.delay : 0;
    let iteration = 0;
    el.innerHTML = el.innerHTML.replace(/([^\s<>]+)(?![^<>]*>)(?:(?=[<\s])|$)/g, (text) => {
      iteration++;
      return `<span class="word" style="transition-delay: ${delay + (iteration * 110)}ms">${text}</span>`;
    });
  },
});
