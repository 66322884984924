import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=bc57a840&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/vercel/path0/frontend/components/ui/Footer.vue').default})
