
export default {
  async fetch() {
    await this.$liveEdit.loadTexts();
  },
  head() {
    const title = `${this.$config.title} | ${this.$liveEdit.getText('home.hero.title')}`;
    const description = this.$striptags(this.$liveEdit.getText('home.hero.description'));

    return {
      ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      title,
      meta: [
        { hid: 'og:title', property: 'og:title', content: title },
        { hid: 'description', name: 'description', content: description },
        { hid: 'og:description', property: 'og:description', content: description },
        { hid: 'twitter:description', property: 'twitter:description', content: description },
      ],
    };
  },
  watch: {
    $route() {
      this.$store.commit('ui/setIsBurgerMenuOpen', false);
      window.scrollTo(0, 0);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
};
